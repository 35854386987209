import ScoreCard from "./pages/scoreCard";
import buildNumber from "buildNumber";
import { version } from "../package.json";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route path="/backnine" component={ScoreCard} />
          <Redirect to="/backnine" />
        </Switch>
      </BrowserRouter>
      <div className="p-4 text-center text-xs opacity-30">
        version {version} {buildNumber}
      </div>
    </div>
  );
}

export default App;
