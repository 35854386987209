const Row = ({ tableIndex, player, playerIndex, onChangeValue }) => {
  const total = player.reduce((res, i) => {
    if (!isNaN(parseInt(i))) return (res += parseInt(i));
    return res;
  }, 0);

  return (
    <tr key={playerIndex}>
      <td
        className={`
            border border-gray-600 px-2 text-xs sm:text-base
            ${tableIndex === 1 ? "lg:hidden" : ""}
        `}
      >
        P{playerIndex + 1}
      </td>
      {player.map((value, valueIndex) => (
        <td key={valueIndex} className="border border-gray-600 w-24">
          <input
            type="tel"
            className="w-full text-center border-0 outline-none focus:outline-none focus:bg-blue-200 px-0 py-1"
            value={value}
            onChange={(e) => onChangeValue(tableIndex, playerIndex, valueIndex, e.target.value)}
          />
        </td>
      ))}
      <td className="border border-gray-600 w-24 text-center">{total > 0 ? total : ""}</td>
    </tr>
  );
};

export default Row;
