import Page from "components/Page";
import { useEffect, useState } from "react";
import backnineLogo from "assets/backnine-logos/backnine-logo.svg";
import wabobaDarkLogo from "assets/backnine-logos/waboba-dark.svg";
import fbLogo from "assets/backnine-logos/fb.svg";
import ttLogo from "assets/backnine-logos/tt.svg";
import igLogo from "assets/backnine-logos/ig.svg";
import ytLogo from "assets/backnine-logos/yt.svg";
import { Helmet } from "react-helmet";
import Row from "./Row";

const initialScores = [[...Array(4).keys()].map(() => [...Array(9).keys()].map(() => "")), [...Array(4).keys()].map(() => [...Array(9).keys()].map(() => ""))];

const initalRules = [...Array(3).keys()].map(() => "");

const ScoreCard = () => {
  const savedScores = localStorage.getItem("scores");
  const [scores, setScores] = useState(savedScores ? JSON.parse(savedScores) : initialScores);
  useEffect(() => {
    localStorage.setItem("scores", JSON.stringify(scores));
  }, [scores]);

  const savedRules = localStorage.getItem("rules");
  const [rules, setRules] = useState(savedRules ? JSON.parse(savedRules) : initalRules);
  useEffect(() => {
    localStorage.setItem("rules", JSON.stringify(rules));
  }, [rules]);

  function onChangeValue(tableIndex, playerIndex, valueIndex, value) {
    setScores((prev) =>
      prev.map((prevTable, prevTableIndex) => {
        if (prevTableIndex !== tableIndex) return prevTable;
        return prevTable.map((p, prevIndex) => {
          if (prevIndex === playerIndex)
            return p.map((prevValue, prevValueIndex) => {
              if (prevValueIndex === valueIndex) return value;
              return prevValue;
            });
          return p;
        });
      })
    );
  }

  function reset(type) {
    let message = `Are you sure to reset the ${type}?`;
    if (window.confirm(message)) {
      if (type === "scores") {
        setScores(initialScores);
      } else if (type === "rules") {
        setRules(initalRules);
      } else {
        setScores(initialScores);
        setRules(initalRules);
      }
    }
  }

  return (
    <Page>
      <Helmet>
        <title>Backnine Score Card - Waboba</title>
      </Helmet>
      <header className="container mx-auto flex justify-center pt-8 lg:pt-16 lg:pb-16">
        <img src={backnineLogo} alt="BACKNINE" className="w-44" />
      </header>

      <div className="container mx-auto max-w-5xl px-2 sm:px-4 py-4">
        <div className="mt-4 overflow-auto relative lg:flex lg:space-x-6">
          {scores.map((players, tableIndex) => (
            <div key={tableIndex}>
              <table className="border-collapse border border-gray-800 bg-white mb-4 w-full">
                <thead>
                  <tr>
                    <th
                      className={`
                    border border-gray-600 px-1
                    ${tableIndex === 1 ? "lg:hidden" : ""}
                  `}
                    >
                      <span className="hidden sm:block sm:px-1">Player</span>
                    </th>
                    {[...Array(9).keys()].map((i) => (
                      <th key={i} className="border border-gray-600 p-1 xs:p-2">
                        {i + 1}
                      </th>
                    ))}
                    <th className="border border-gray-600 p-1 text-xs sm:text-base sm:p-2">Sum</th>
                  </tr>
                </thead>

                <tbody>
                  {players.map((player, playerIndex) => (
                    <Row key={playerIndex} playerIndex={playerIndex} player={player} tableIndex={tableIndex} onChangeValue={onChangeValue} />
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>

        <div className="mt-8 w-full overflow-auto">
          <table className="border-collapse border border-gray-800 bg-white w-full">
            <thead>
              <tr>
                <th className="border border-gray-600 p-1 sm:p-2" colSpan={2}>
                  Target Zone Rules
                </th>
              </tr>
            </thead>

            <tbody>
              {rules.map((rule, ruleIndex) => (
                <tr key={ruleIndex}>
                  <td className="border border-gray-600 px-2 w-20">Rule {ruleIndex + 1}</td>
                  <td className="border border-gray-600">
                    <input
                      className="w-full border-0 outline-none focus:outline-none focus:bg-blue-300 px-1"
                      value={rule}
                      onChange={(e) => setRules(rules.map((prevRule, prevIndex) => (prevIndex === ruleIndex ? e.target.value : prevRule)))}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="text-center mt-8 text-xs text-gray-500">This page autosaves. You can refresh your browser without losing data.</div>

        <div className="flex justify-center mt-4 space-x-4 text-blue-500 font-bold text-sm">
          <button className="" onClick={() => reset("scores")}>
            Reset Scores
          </button>
          <button onClick={() => reset("rules")}>Reset Rules</button>
          <button onClick={() => reset("all")}>Reset All</button>
        </div>

        <div className="flex flex-col items-center">
          <a className="w-32 mt-12 block" href="https://waboba.com" rel="noreferrer" target="_blank">
            <img src={wabobaDarkLogo} alt="Waboba" className="w-full" />
          </a>

          <div className="mt-4">
            <div className="text-xs">Follow @waboba & tag #keeplifefun</div>
            <div className="flex justify-center space-x-2 mt-2">
              <a href="https://www.facebook.com/wabobaball" target="_blank" rel="noreferrer">
                <img src={fbLogo} alt="Facebook - Waboba" className="w-8" />
              </a>
              <a href="https://twitter.com/Waboba" target="_blank" rel="noreferrer">
                <img src={ttLogo} alt="Twitter - Waboba" className="w-8" />
              </a>
              <a href="https://www.instagram.com/waboba/" target="_blank" rel="noreferrer">
                <img src={igLogo} alt="Instagram - Waboba" className="w-8" />
              </a>
              <a href="https://www.youtube.com/user/TheWabobaTeam" target="_blank" rel="noreferrer">
                <img src={ytLogo} alt="Youtube - Waboba" className="w-8" />
              </a>
            </div>
          </div>

          <div className="mt-6 italic underline">
            <a href="https://waboba.com" target="_blank" rel="noreferrer">
              waboba.com
            </a>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default ScoreCard;
